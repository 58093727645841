define("additive-account/adapters/room-count", ["exports", "additive-account/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RoomCountAdapter extends _application.default {
    get namespace() {
      return `${super.namespace}/settings`;
    }
  }
  _exports.default = RoomCountAdapter;
});
define("additive-account/routes/instances/instance/settings/organization-settings", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstancesInstanceSettingsOrganizationSettingsRoute = _exports.default = (_class = class InstancesInstanceSettingsOrganizationSettingsRoute extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
    }
    setupController(controller) {
      var _controller$globalUse, _controller$globalUse2, _controller$currentUs, _this$currentUser, _this$currentUser2, _this$currentUser3;
      super.setupController(...arguments);
      const isAdditive = (_controller$globalUse = controller.globalUser) === null || _controller$globalUse === void 0 ? void 0 : _controller$globalUse.isAdditive;
      const isPartner = (_controller$globalUse2 = controller.globalUser) === null || _controller$globalUse2 === void 0 ? void 0 : _controller$globalUse2.isPartner;
      const isSubOrg = !(controller !== null && controller !== void 0 && (_controller$currentUs = controller.currentUser) !== null && _controller$currentUs !== void 0 && (_controller$currentUs = _controller$currentUs.currentOrganization) !== null && _controller$currentUs !== void 0 && _controller$currentUs.isPartner);
      const hasPartnerPrivileges = isAdditive && ((_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.isAdmin) || isPartner && isSubOrg && ((_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.isAdmin);
      const hasPrivileges = hasPartnerPrivileges || ((_this$currentUser3 = this.currentUser) === null || _this$currentUser3 === void 0 || (_this$currentUser3 = _this$currentUser3.user) === null || _this$currentUser3 === void 0 ? void 0 : _this$currentUser3.isSuperAdmin);
      if (hasPrivileges) {
        return;
      }
      return this.router.transitionTo('instances.instance.settings');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});
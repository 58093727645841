define("additive-account/validations/settings", ["exports", "ember-changeset-validations/validators", "additive-account/validators/salutation"], function (_exports, _validators, _salutation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.simpleSalutationValidation = _exports.roomCountValidation = _exports.integrationValidation = _exports.genderSalutationValidation = _exports.closingPeriodValidation = void 0;
  const roomCountValidation = intl => {
    const countMessage = intl.t('global.errors.positiveNumber');
    const presenceMessage = intl.t('global.errors.required');
    return {
      count: (0, _validators.validateNumber)({
        integer: true,
        gt: 0,
        message: countMessage
      }),
      startsAt: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
  };
  _exports.roomCountValidation = roomCountValidation;
  const closingPeriodValidation = intl => {
    const presenceMessage = intl.t('global.errors.required');
    return {
      from: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      until: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
  };
  _exports.closingPeriodValidation = closingPeriodValidation;
  const integrationValidation = (intl, type, requiresUrl) => {
    const presenceMessage = intl.t('global.errors.required');
    const validation = ['aida', 'gastrodat'].includes(type) ? {
      token: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    } : {
      user: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      password: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
    if (requiresUrl) {
      validation.url = (0, _validators.validatePresence)({
        presence: requiresUrl,
        message: presenceMessage
      });
    }
    return validation;
  };
  _exports.integrationValidation = integrationValidation;
  const genderSalutationValidation = intl => {
    const presenceMessage = intl.t('global.errors.required');
    return {
      f: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _salutation.validateSalutationPlaceholders)({
        message: intl.t('global.errors.invalidSalutationPlaceholder')
      })],
      m: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _salutation.validateSalutationPlaceholders)({
        message: intl.t('global.errors.invalidSalutationPlaceholder')
      })]
    };
  };
  _exports.genderSalutationValidation = genderSalutationValidation;
  const simpleSalutationValidation = intl => {
    const presenceMessage = intl.t('global.errors.required');
    return {
      value: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _salutation.validateSalutationPlaceholders)({
        message: intl.t('global.errors.invalidSalutationPlaceholder')
      })]
    };
  };
  _exports.simpleSalutationValidation = simpleSalutationValidation;
});
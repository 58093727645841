define("additive-account/router", ["exports", "@ember/routing/router", "additive-account/config/environment", "@additive-apps/auth/router"], function (_exports, _router, _environment, _router2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Router extends _router.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('auth', function () {
      this.route('login', function () {
        this.route('reset');
      });
      this.route('signup');

      // added new callback route from auth-addon
      (0, _router2.default)(this);
    });
    this.route('confirm');
    this.route('invite');
    this.route('logout');
    this.route('organization');
    this.route('instances', {
      path: '/'
    }, function () {
      this.route('create');
      this.route('me', function () {
        this.route('inbox', function () {
          this.route('message', {
            path: ':message_id'
          });
        });
      });
      this.route('instance', {
        path: ':instance_id'
      }, function () {
        this.route('organization', function () {
          this.route('index', {
            path: '/'
          });
        });
        this.route('apps');
        this.route('users', function () {
          this.route('list', {
            path: '/'
          }, function () {
            this.route('user', {
              path: ':user_id'
            });
          });
          this.route('partner');
        });
        this.route('billings');
        this.route('email-templates', function () {
          this.route('list', {
            path: '/'
          }, function () {
            this.route('detail', {
              path: ':email_template_id'
            });
          });
          this.route('edit', {
            path: ':email_template_id/edit'
          });
        });
        this.route('corporate-design', function () {
          this.route('logo');
          this.route('typography');
        });
        this.route('auditlog');
        this.route('settings', function () {
          this.route('communication');
          this.route('legals');
          this.route('room-counts');
          this.route('integrations');
          this.route('closing-periods');
          this.route('organization-settings');
        });
      });
    });
    this.route('loading');
    this.route('page-not-found', {
      path: '/not-found'
    });
    this.route('page-not-found', {
      path: '/*wildcard'
    });
  });
});